// Fonts
@import '~roboto-fontface';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~jquery-datetimepicker/jquery.datetimepicker.css';

body {
    font-family: Roboto-Light, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.5 !important;
}

.frontEnd {
    background-color: #e6e6e6;
    min-height: 100vh;
    background-repeat: no-repeat;

    .headLineCase {
        color: #FFF;
        background-color: #f70c00;
        text-align: center;
        padding: 10px;
    }

    .progressCase {
        background-color: #e6e6e6;
        padding: 10px;

        .progress-bar {
            background-color: #f70c00;
        }
    }

    .bodyCase {
        background-color: #e6e6e6;
        color: #000;
        padding: 25px;

        input[type=text] {
            margin-left: 20px;
        }

        .inputInvisible {
            display: none;
        }
    }

    .buttonCase {
        background-color: #e6e6e6;
        text-align: right;

        .btn-primary {
            color: #FFF;
            background-color: #f70c00;
            border-radius: 0;
            border: none;
            font-weight: bold;
        }
    }
}

#multipleAnswerValidationHint {
    div {
        color: #000;
        background-color: #e6e6e6;

        button {
            color: #FFF;
            background-color: #f70c00;
            border-radius: 0;
            border: none;
            font-weight: bold;
        }
    }
}

.evaluation-table {
    tr {
        td {

        }
        th:last-child, td:last-child {
            text-align: right;
        }
    }
}

.frame-wrapper {
    width: 100%;
    height: 200px;
    border-radius: 25px;
    border: 2px dashed black;
    position: relative;
    overflow: hidden;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 4;
    }
    p {
        font-size: 16px;
        width: max-content;
    }
    label {
        width: 100%;
        height: inherit;
        cursor: pointer;
        font-size: 16px;
        display: grid;
        place-content: center;
        position: relative;
        z-index: 5;
    }
}

.header {
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    padding: 15px;
    background-color: #e6e6e6;
    img {
        width: inherit;
    }
}
